import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlertFilters } from 'src/modules/types/interfaces';

@Injectable({
  providedIn: 'root'
})
export class ExportCardsService {
  data;
  sub;
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient, private spinnerService: NgxSpinnerService) {
  }

  downloadCards(arg: AlertFilters): void {
    this.spinnerService.show().then();
    arg.issuer = arg.issuer === ' ' ? 'all' : arg.issuer;
    arg.alertType = arg.alertType === ' ' ? 'all' : arg.alertType;
    arg.productFamily = arg.productFamily === ' ' ? 'all' : arg.productFamily;
    arg.searchKey = arg.searchKey ? arg.searchKey : 'all';
    arg.searchSiteCode = arg.searchSiteCode ? arg.searchSiteCode : 'all';
    arg.pcomNumber = arg.pcomNumber ? arg.pcomNumber : '';
    arg.minScore = arg.minScore ? arg.minScore : 0;
    arg.maxScore = arg.maxScore ? arg.maxScore : 0;
    arg.minMlScore = arg.minMlScore ? arg.minMlScore : 0;
    arg.maxMlScore = arg.maxMlScore ? arg.maxMlScore : 0;
    arg.minAmt = arg.minAmt ? arg.minAmt : 0;
    arg.maxAmt = arg.maxAmt ? arg.maxAmt : 0;
    arg.minTransactions = arg.minTransactions ? arg.minTransactions : 0;
    arg.maxTransactions = arg.maxTransactions ? arg.maxTransactions : 0;
    arg.minRules = arg.minRules ? arg.minRules : 0;
    arg.maxRules = arg.maxRules ? arg.maxRules : 0;
    arg.alertSource = arg.alertSource === ' ' ? 'all' : arg.alertSource;
    
    this.http.get(`${this.apiUrl}/export-cards?start_date=${arg.startDate}&end_date=${arg.endDate}&country=${arg.issuer}&status=${arg.status}&status_affiliate=${arg.statusAffiliate}&type=${arg.alertType}&product_family_code=${arg.productFamily}&search_key=${arg.searchKey}&search_site_code=${arg.searchSiteCode}&pcom_number=${arg.pcomNumber}&min_score=${arg.minScore}` +
      `&max_score=${arg.maxScore}&min_amount=${arg.minAmt}&max_amount=${arg.maxAmt}&alert_source=${arg.alertSource}&min_ml_score=${arg.minMlScore}&max_ml_score=${arg.maxMlScore}&min_transactions=${arg.minTransactions}&max_transactions=${arg.maxTransactions}&min_rules=${arg.minRules}&max_rules=${arg.maxRules}`, {
      responseType: 'json'
    }
    ).subscribe(response => {
      const task_id = JSON.parse(JSON.stringify(response)).task_id;

      this.sub = timer(0, 10000).pipe(
        map(() => {
          this.http.get(`${this.apiUrl}/export-card-status?task_id=${task_id}`, {
            responseType: 'json'
          }
          ).subscribe((res) => {
            if (res) {
              this.data = JSON.parse(JSON.stringify(res));
              if (this.data.status === 200) {
                const export_csv_url = this.data.export_csv_url;
                window.open(export_csv_url, '_self');
                this.sub.unsubscribe();
                this.spinnerService.hide().then();
              }
            }
          });
        })
      ).subscribe();
    }, error => {
      this.spinnerService.hide().then();
    });
  }
}
